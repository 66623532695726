import type { DataType, Value } from './value';
import type { ChangeValueData } from '.';

export type RpgConfigHudElement = {
  id: string;
  value: Value;
  displayName?: string;
  displayMaxValue?: boolean;
  icon: string;
};

export type RpgConfigAiGeneration = {
  id: string;
  value: Value;
  displayName?: string;
  description?: string;
};

export type NumberPropertyConfig = {
  dataType: DataType.Number;
  defaultValue: number;
  maxValue?: Value;
  minValue?: Value;
};

export type StringPropertyConfig = {
  dataType: DataType.String;
  defaultValue: string;
};

export type EnumPropertyConfig = {
  dataType: DataType.Enum;
  options: EnumOption[];
  enumRef: string;
};

export type EnumOption = {
  ref: string;
  label: string;
  description?: string;
};

export type PropertyConfig =
  | NumberPropertyConfig
  | StringPropertyConfig
  | EnumPropertyConfig;

/**
 * Determines how a property should be reset
 */
export enum PropertyScope {
  /**
   * The property will be stored at the character level
   * and will never be manually reset once created.
   */
  // Character = 'character',

  /**
   * The property will be automatically reset to the default
   * value at the start of every episode.
   */
  Episode = 'episode',

  /**
   * The property will be automatically reset to the default
   * value at the start of the first episode in the series.
   */
  Series = 'series',

  /**
   * The property will be reset to the default value whenever
   * the player restarts from a checkpoint. It also resets at
   * the start of each episode.
   */
  // Checkpoint = 'checkpoint',
}

export type RpgConfigProperty = {
  id: string;
  name: string;
  scope: PropertyScope;
  config: PropertyConfig;
};

export type RpgConfigCustomItem = {
  id: string;
  itemRef: string;
  consumable: boolean;
  label: string;
  effects: ChangeValueData[];
};

export type RpgConfig = {
  version: 2;
  hud: RpgConfigHudElement[];
  properties: RpgConfigProperty[];
  customItems: RpgConfigCustomItem[];
  aiGeneration?: RpgConfigAiGeneration[];
};

export const coinTossImage =
  'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/coin.png';
export const singleSelectOptionBackground =
  'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/single-select/option-background.png';

export type EpisodeSetupData = {
  version: 1;
  properties: RpgConfigProperty[];
};

export type HudIcon = { ref: string; name: string; icon: string };

/**
 * TODO we should move this to a database table
 */
export const hudIcons = [
  {
    ref: 'heart',
    name: 'Heart',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/heart.png',
  },
  {
    ref: 'clover',
    name: 'Clover',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/clover.png',
  },
  {
    ref: 'bag',
    name: 'Bag',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/bag.png',
  },
  {
    ref: 'thunder',
    name: 'Thunder',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/thunder.png',
  },
  {
    ref: 'brain',
    name: 'Brain',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/brain.png',
  },
  {
    ref: 'charm',
    name: 'Charm',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/charm.png',
  },
  {
    ref: 'chat',
    name: 'Chat',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/chat.png',
  },
  {
    ref: 'flex',
    name: 'Flex',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/flex.png',
  },
  {
    ref: 'hand',
    name: 'Hand',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/hand.png',
  },
  {
    ref: 'rabbit',
    name: 'Rabbit',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/rabbit.png',
  },
  {
    ref: 'ai',
    name: 'AI',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/ai.png',
  },
  {
    ref: 'angel',
    name: 'Angel',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/angel.png',
  },
  {
    ref: 'bow-and-arrow',
    name: 'Bow and Arrow',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/bow-and-arrow.png',
  },
  {
    ref: 'coin',
    name: 'Coin',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/coin.png',
  },
  {
    ref: 'crossed-swords',
    name: 'Crossed Swords',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/crossed-swords.png',
  },
  {
    ref: 'drama-masks',
    name: 'Drama Masks',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/drama-masks.png',
  },
  {
    ref: 'health',
    name: 'Health',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/health.png',
  },
  {
    ref: 'imp',
    name: 'Imp',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/imp.png',
  },
  {
    ref: 'seedling',
    name: 'Seedling',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/seedling.png',
  },
  {
    ref: 'shield',
    name: 'Shield',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/shield.png',
  },
  {
    ref: 'skull',
    name: 'Skull',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/skull.png',
  },
  {
    ref: 'sparkles',
    name: 'Sparkles',
    icon: 'https://kbhbkigxfpehzlqqjfpb.supabase.co/storage/v1/object/public/images/hud-icons/sparkles.png',
  },
];
