import { Button, ModalFooter } from '@chakra-ui/react';
import {
  DataType,
  RpgConfigAiGeneration,
  RpgConfigProperty,
  ValueType,
} from '@common/studio-types';
import {
  Icon,
  List,
  ListBody,
  ListHeader,
  ListHeaderColumn,
  useMaestroToast,
} from '@maestro/components';
import { dimensions, rawDimensions } from '@maestro/styles';
import { useState } from 'react';
import styled from 'styled-components';
import { AiGenerationEmptyState } from './AiGenerationEmptyState';
import { AiGenerationForm } from './AiGenerationForm';
import { AiGenerationRow } from './AiGenerationRow';

type Props = {
  aiGenerations: RpgConfigAiGeneration[];
  onAiGenerationsChange: (elements: RpgConfigAiGeneration[]) => void;
  properties: RpgConfigProperty[];
};

export const AiGenerationConfiguration: React.FC<Props> = (props) => {
  const { aiGenerations, properties, onAiGenerationsChange } = props;

  const onAddAiGeneration = (newElement: RpgConfigAiGeneration) => {
    const newElements: RpgConfigAiGeneration[] = [...aiGenerations, newElement];
    onAiGenerationsChange(newElements);
  };

  const [editAiGeneration, setEditAiGeneration] = useState<
    RpgConfigAiGeneration | undefined
  >();

  const onCreateAiGeneration = () => {
    const aiGeneration: RpgConfigAiGeneration = {
      id: String(Date.now()),
      displayName: '',
      value: {
        dataType: DataType.Number,
        type: ValueType.Property,
        ref: properties[0]?.id,
      },
    };

    setEditAiGeneration(aiGeneration);
  };

  const onAiGenerationChange = (newAiGeneration: RpgConfigAiGeneration) => {
    if (aiGenerations.find((item) => item.id === newAiGeneration.id)) {
      const newAiGenerations = aiGenerations.map((item) =>
        item.id === newAiGeneration.id ? newAiGeneration : item,
      );

      onAiGenerationsChange(newAiGenerations);
    } else {
      onAddAiGeneration(newAiGeneration);
    }
  };

  const onRemoveAiGeneration = (aiGeneration: RpgConfigAiGeneration) => {
    const newAiGenerations = aiGenerations.filter(
      (item) => item.id !== aiGeneration.id,
    );
    onAiGenerationsChange(newAiGenerations);
  };

  const toast = useMaestroToast();
  const showTooltip = () => {
    toast({
      status: 'warning',
      title: 'No properties have been created. ',
      description: 'Add a new property to add it to the player menu',
    });
  };

  return (
    <>
      <HudForm>
        {aiGenerations.length === 0 ? (
          <AiGenerationEmptyState
            onCreateElement={onAddAiGeneration}
            properties={properties}
          />
        ) : (
          <List
            draggable
            onReorder={onAiGenerationsChange}
            records={aiGenerations}
          >
            <ListHeader draggable>
              <ListHeaderColumn width="30%">Item</ListHeaderColumn>
              <ListHeaderColumn flex>Value</ListHeaderColumn>
              <ListHeaderColumn width={dimensions.size120} />
            </ListHeader>
            <ListBody>
              {aiGenerations.map((aiGeneration) => (
                <AiGenerationRow
                  key={aiGeneration.id}
                  aiGeneration={aiGeneration}
                  onEdit={() => setEditAiGeneration(aiGeneration)}
                  onRemove={() => onRemoveAiGeneration(aiGeneration)}
                />
              ))}
            </ListBody>
          </List>
        )}
      </HudForm>
      <AiGenerationForm
        properties={properties}
        aiGeneration={editAiGeneration}
        onChange={onAiGenerationChange}
        onRemove={() => onRemoveAiGeneration(editAiGeneration!)}
        onClose={() => setEditAiGeneration(undefined)}
        isOpen={!!editAiGeneration}
      />
      {aiGenerations.length < 10 && (
        <ModalFooter justifyContent="start">
          <Button
            leftIcon={<Icon name="plus" size={rawDimensions.size16} />}
            variant="default"
            onClick={
              properties.length === 0 ? showTooltip : onCreateAiGeneration
            }
          >
            Add to AI generation form
          </Button>
          <Divider />
        </ModalFooter>
      )}
    </>
  );
};

const HudForm = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: ${dimensions.size640};
`;

const Divider = styled.div`
  flex: 1;
`;
