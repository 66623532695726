import { Button, Tooltip } from '@chakra-ui/react';
import { Icon } from '@maestro/components/Icon';
import { FeatureFlags, useFeatureFlag } from '@maestro/feature-flags';
import { dimensions, rawDimensions, textStyles } from '@maestro/styles';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { InterpolatedText } from '../components/value/InterpolatedText';
import { useDrawerStore } from '../hooks/useDrawerStore';
import {
  useCanDelete,
  useCanEdit,
  useStudioFlowStore,
} from '../hooks/useStudioFlowStore';

type Props = PropsWithChildren<{
  nodeId: string;
  title: string;
  subtitle?: string;
  className?: string;
  selected?: boolean;
  disableEditing?: boolean;
  tag?: string;
  hideControls?: boolean;
  aiInstructions?: string;
}>;

export const BaseStudioNode: React.FC<Props> = (props) => {
  const selectNode = useStudioFlowStore((state) => state.selectNode);
  const deleteNode = useStudioFlowStore((state) => state.deleteNode);
  const isSimulatorEnabled = useFeatureFlag(FeatureFlags.NativeSimulator);
  const isAiTemplateEpisodesEnabled = useFeatureFlag(
    FeatureFlags.AiTemplateEpisodes,
  );
  const openDrawer = useDrawerStore((state) => state.openDrawer);
  const canEdit = useCanEdit();
  const canDelete = useCanDelete();

  return (
    <Container
      className={`studio-node ${props.className || ''}`}
      $selected={props.selected}
      aria-label={props.title}
    >
      {props.tag && (
        <TagContainer>
          <Tag>{props.tag}</Tag>
        </TagContainer>
      )}
      <TitleContainer>
        <Title>{props.title}</Title>
        {!props.hideControls && (
          <ButtonsContainer>
            {isSimulatorEnabled && (
              <Tooltip placement="bottom" label="Play from this node">
                <Button
                  variant="studioNode"
                  onClick={() =>
                    openDrawer('play-episode', {
                      type: 'play-episode',
                      nodeId: props.nodeId,
                    })
                  }
                >
                  <Icon name="play" size={rawDimensions.size16} />
                </Button>
              </Tooltip>
            )}
            {!props.disableEditing && canEdit && (
              <Tooltip placement="bottom" label="Edit node">
                <Button
                  variant="studioNode"
                  onClick={() => selectNode(props.nodeId)}
                >
                  <Icon name="edit" size={rawDimensions.size16} />
                </Button>
              </Tooltip>
            )}

            {canDelete && (
              <Tooltip placement="bottom" label="Delete node">
                <Button
                  variant="studioNode"
                  onClick={() => deleteNode(props.nodeId)}
                >
                  <Icon name="trash" size={rawDimensions.size16} />
                </Button>
              </Tooltip>
            )}
          </ButtonsContainer>
        )}
      </TitleContainer>
      {props.subtitle && (
        <Subtitle>
          <InterpolatedText text={props.subtitle} />
        </Subtitle>
      )}
      <ContentContainer>{props.children}</ContentContainer>
      {isAiTemplateEpisodesEnabled && props.aiInstructions && (
        <AiInstructionContainer>
          <AiInstructionLabel>AI Instructions</AiInstructionLabel>
          <InterpolatedText text={props.aiInstructions} />
        </AiInstructionContainer>
      )}
    </Container>
  );
};

const AiInstructionLabel = styled.div`
  ${textStyles.label.lb10sb}
  color: ${({ theme }) => theme.colors.text.header};
  margin-bottom: ${dimensions.size8};
`;

const AiInstructionContainer = styled.div`
  ${textStyles.body.b10m}
  color: ${({ theme }) => theme.colors.text.body};
  border-radius: ${dimensions.size0} ${dimensions.size0} ${dimensions.size8}
    ${dimensions.size8};
  padding: ${dimensions.size16};
  background: ${({ theme }) => theme.colors.background.shade};
`;

const Container = styled.div<{ $selected?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: ${dimensions.size360};
  max-width: ${dimensions.size360};
  background: ${({ theme }) => theme.colors.base.dark['1000']};
  border: solid ${dimensions.size1}
    ${({ theme }) => theme.colors.base.dark['1000']};
  border-color: ${({ $selected, theme }) =>
    $selected
      ? theme.colors.base.accent['1000']
      : theme.colors.border.default['100']};
  border-radius: ${dimensions.size8};
`;

const TagContainer = styled.div`
  display: flex;
  margin-top: -${rawDimensions.size1}px;
  margin-left: -${rawDimensions.size1}px;
`;

const Tag = styled.div`
  ${textStyles.label.lb12sb}
  padding: ${dimensions.size4} ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.header};
  background: ${({ theme }) => theme.colors.background.accent};
  border-radius: ${dimensions.size8} ${dimensions.size0} ${dimensions.size8}
    ${dimensions.size0};
`;

const ContentContainer = styled.div`
  ${textStyles.body.b12m}
  display: flex;
  flex-direction: column;
  padding: ${dimensions.size8} ${dimensions.size16} ${dimensions.size16}
    ${dimensions.size16};
  color: ${({ theme }) => theme.colors.text.body};
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size16};
  align-items: center;
  justify-content: space-between;
  padding: ${dimensions.size12} ${dimensions.size16} ${dimensions.size8}
    ${dimensions.size16};
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${dimensions.size8};
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  ${textStyles.body.b14sb}
  color: ${({ theme }) => theme.colors.text.header};
`;

const Subtitle = styled.div`
  ${textStyles.body.b12m}
  padding: ${dimensions.size8} ${dimensions.size16};
  font-size: ${dimensions.size12};
  color: ${({ theme }) => theme.colors.text.body};
`;
